import React, { useState } from "react"
import tw from "twin.macro"
import styled from "@emotion/styled"

import Inner from "../components/Inner"
import Image from "../components/Image"

import * as Color from "../utils/color"
import { mobile } from "../utils/breakpoint"

const ImagesList = styled.div`
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: max-content;
	grid-auto-rows: 100px;
	grid-gap: 16px;

	${Image} {
		border: solid 1px lightgray;
		object-fit: contain;
		max-height: 100px;
		border-radius: 8px;
	}
`

const Type = styled.img`
	border: solid 1px lightgray;
	object-fit: cover;
	width: 100%;
	height: 100%;
	max-width: 60px;
	max-height: 60px;
	border-radius: 50%;
`

const TypesList = styled.div`
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: 60px;
	grid-auto-rows: 60px;
	grid-gap: 16px;
`

const Body = styled.div`
	${tw`text-base md:text-lg`}

	ul {
		list-style-type: "✅";
		li {
			margin-left: 16px;
			padding-left: 8px;
		}
	}
`

const Head = styled.div`
	${tw`text-lg tracking-wide`}
	font-weight: bold;
`

const Description = styled.div``

const Features = styled.div``

const Types = styled.div``

const Price = styled.div`
	${tw`text-3xl`}
	font-weight: bold;
	color: lightgray;
`

const Short = styled.div`
	${tw`text-xl leading-tight`}
	font-weight: bold;
	color: lightgray;
`

const Name = styled.div`
	${tw`text-4xl leading-none`}
	font-weight: bold;

	color: ${Color.primary};
`

const Category = styled.div`
	${tw`text-xs tracking-wide`}
	text-transform: uppercase;
	font-weight: bold;

	color: ${Color.secondary};
`

const Thumbnail = styled.img`
	width: 100%;
	height: calc(100vh - 300px);
	object-fit: cover;
	border-radius: 8px;

	@media ${mobile} {
		height: 300px;
	}
`

const ImagesContainer = styled.div``

const Show = styled.div``

const Detail = styled.div`
	height: min-content;
	display: grid;
	grid-template-areas:
		"category price"
		"name price"
		"short price"
		"types types "
		"features features"
		"description description"
		"images images";

	${Head} {
		${tw`mb-1`}
	}

	${Category} {
		${tw`mb-1`}
		grid-area: category;
	}

	${Name} {
		${tw`mb-1`}
		grid-area: name;
	}

	${Short} {
		${tw`mb-2`}
		grid-area: short;
	}

	${Price} {
		grid-area: price;
		align-self: center;
		justify-self: flex-end;
	}

	${Types} {
		${tw`mb-2`}
		grid-area: types;
	}

	${Features} {
		${tw`mb-2`}
		grid-area: features;
	}

	${Description} {
		${tw`mb-2`}
		grid-area: description;
	}

	${ImagesContainer} {
		${tw`mb-2`}
		grid-area: images;
	}
`

const Product = styled.article`
	display: grid;
	grid-auto-columns: 1fr;
	grid-template-areas: "show detail";
	grid-column-gap: 32px;

	${Show} {
		grid-area: show;
	}

	${Detail} {
		grid-area: detail;
	}

	@media ${mobile} {
		grid-template-areas: "show" "detail";

		${Show} {
			${tw`mb-8`}
		}
	}
`

const Content = styled.div`
	padding: 2em 0;
`

const ProductLayout = ({ data }) => {
	const {
		name,
		shortDescription,
		price,
		features,
		description,
		productCategory,
		thumbnail,
		types,
		images,
	} = data.strapiProducts

	const [selectedItem, setSelectedItem] = useState(thumbnail)
	const [selectedPrice, setSelectedPrice] = useState(price)

	return (
		<>
			<Inner>
				<Content>
					<Product>
						<Detail>
							<Category>{productCategory.name}</Category>
							<Name>{name}</Name>
							<Short>{shortDescription}</Short>
							<Price>{"$" + selectedPrice}</Price>
							{types.length > 0 && (
								<Types>
									<Head>Types</Head>
									<TypesList>
										<Type
											src={thumbnail.publicURL}
											onClick={() => {
												setSelectedItem(thumbnail)
												setSelectedPrice(price)
											}}
										/>
										{types.map(item => {
											return (
												<Type
													onClick={() => {
														setSelectedItem(item.model)
														setSelectedPrice(item.price)
													}}
													src={item.model.publicURL}
												/>
											)
										})}
									</TypesList>
								</Types>
							)}
							<Features>
								<Head>Features</Head>
								<Body dangerouslySetInnerHTML={{ __html: features }} />
							</Features>
							<Description>
								<Head>Description</Head>
								<Body>{description}</Body>
							</Description>
							{images.length > 0 && (
								<ImagesContainer>
									<Head>More Images</Head>
									<ImagesList>
										{images.map((item, index) => {
											return <Image key={index} imageUrl={`${process.env.API_URL}${item.url}`} />
										})}
									</ImagesList>
								</ImagesContainer>
							)}
						</Detail>

						<Show>
							<Thumbnail src={selectedItem.publicURL} />
						</Show>
					</Product>
				</Content>
			</Inner>
		</>
	)
}

export const query = graphql`
	query($id: String!) {
		strapiProducts(id: { eq: $id }) {
			name
			shortDescription
			price
			features
			description
			productCategory {
				name
			}
			thumbnail {
				publicURL
			}
			images {
				url
			}
			types {
				name
				price
				model {
					publicURL
				}
			}
		}
	}
`

export default ProductLayout
