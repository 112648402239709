import React from "react"
import styled from "@emotion/styled"
import { motion } from "framer-motion"

import * as zIndex from "../../utils/z-index"
import { mobile } from "../../utils/breakpoint"

const Overlay = styled(motion.div)`
	width: 100%;
	height: 100%;

	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: ${zIndex.z_modal + 2};

	background-color: black;
`

const Image = styled(motion.img)`
	border-radius: 8px;
	background-color: white;
	height: calc(100vh - 20vh);

	@media ${mobile} {
		height: calc(100vh - 60vh);
	}
`

const Content = styled(motion.div)`
	z-index: ${zIndex.z_modal + 3};
	/* padding: 20%; */
`

const Modal = styled(motion.div)`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: ${zIndex.z_modal + 1};

	display: flex;
	justify-content: center;
	align-items: center;
`

const ImageModal = ({ imageUrl, imageAlt, showModal }) => {
	const handleClick = () => {
		showModal(false)
	}

	return (
		<Modal onClick={handleClick}>
			<Overlay
				initial={{ opacity: 0 }}
				animate={{ opacity: 0.6 }}
				exit={{ opacity: 0 }}
				transition={{ duration: 0.4 }}
			/>

			<Content>
				<Image src={imageUrl} layoutId={"imageView"} alt={imageAlt} />
			</Content>
		</Modal>
	)
}

export default ImageModal
