import React, { useState } from "react"
import { Global, css } from "@emotion/core"
import styled from "@emotion/styled"
import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion"

import ImageModal from "../ImageModal"

const Image = styled(motion.img)`
	background-color: white;
`

const ImageItem = styled(motion.div)``

const modalStyles = css`
	body {
		overflow: hidden;
	}
`

const ImageWrapper = ({ imageUrl, imageAlt, ...props }) => {
	const [visibleModal, setVisibleModal] = useState(false)

	const visibleHandler = value => {
		setVisibleModal(value)
	}

	return (
		<AnimateSharedLayout transition={{ duration: 0.4 }}>
			<ImageItem>
				<AnimatePresence>
					{visibleModal && <Global styles={modalStyles} />}

					{visibleModal && (
						<ImageModal key={"imageModal"} imageUrl={imageUrl} alt={imageAlt} showModal={visibleHandler} />
					)}

					{!visibleModal && (
						<Image layoutId={"imageView"} src={imageUrl} {...props} onClick={() => setVisibleModal(true)} /> // opt this line
					)}
				</AnimatePresence>
			</ImageItem>
		</AnimateSharedLayout>
	)
}

export default styled(ImageWrapper)``
